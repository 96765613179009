let timeScrollStep = 10000;

const statusFullScreen = (div: string): void => {
    const header = document.querySelector('.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr');
    const title = document.querySelector('#title-agenda') as HTMLElement;
    const bodyAgenda = document.querySelector('.fc-view-harness.fc-view-harness-passive') as HTMLElement;
    const timer = document.querySelector('#timer-agenda') as HTMLElement;
    const logo = document.querySelector('#logo-agenda');
    const buttonFullscreen = document.querySelector('#fullScreen-agenda') as HTMLElement
    const elem = document.querySelector(div);

    const exitFullScreen = () => {
        if (header && title && bodyAgenda && timer && buttonFullscreen ) {
            header.classList.remove('fullscreen-servicos');
            title.style.top = '25px';
            bodyAgenda.style.marginTop = '0px';
            timer.style.marginRight = '0px';
            buttonFullscreen.style.position = 'absolute'
            buttonFullscreen.style.zIndex = '0'
        }
    };

    const enterFullScreen = () => {
        if (header && title && bodyAgenda && timer && logo && elem && buttonFullscreen ) {
            header.appendChild(logo);
            header.appendChild(timer);
            header.appendChild(title);
            header.classList.add('fullscreen-servicos');
            title.style.top = '37px';
            bodyAgenda.style.marginTop = '61px';
            timer.style.marginRight = '28px';
            buttonFullscreen.style.position = 'fixed'
            buttonFullscreen.style.zIndex = '100'

            if (hasVerticalScroll(elem)) {
                toggleScroll(elem);
            }
        }
    };

    document.addEventListener("fullscreenchange", () => {
        if (!document.fullscreenElement) {
            exitFullScreen();
        } else if (document.fullscreenElement === elem) {
            enterFullScreen();
        }
    });
};

// Função de exemplo para detectar scroll vertical (deve ser definida conforme sua lógica)
const hasVerticalScroll = (element: Element | null): boolean => {
    return element ? element.scrollHeight > element.clientHeight : false;
};

function toggleScroll(elem: Element): void {
    const scrollStep = elem.clientHeight / 3; // Define o tamanho do passo de scroll
    const numSteps = Math.ceil(elem.scrollHeight / elem.clientHeight); // Calcula o número de etapas necessárias

    let timeoutId; // Variável para armazenar o ID do timeout

    function scrollToBottom(): void {
        let i = 0;
        function step() {
            if (i < numSteps) {
                elem.scrollBy({ top: scrollStep, behavior: "smooth" });
                i++;
                timeoutId = setTimeout(step, timeScrollStep); // Aguarda 2000ms antes de chamar a próxima etapa
            } else {
                clearTimeout(timeoutId); // Limpa o timeout antes de chamar a função para rolar para cima
                scrollToTop(); // Chama a função para rolar para cima
            }
        }
        step();
    }

    function scrollToTop(): void {
        let i = 0;
        function step() {
            if (i < numSteps) {
                elem.scrollBy({ top: -scrollStep, behavior: "smooth" });
                i++;
                timeoutId = setTimeout(step, timeScrollStep); // Aguarda 2000ms antes de chamar a próxima etapa
            } else {
                clearTimeout(timeoutId); // Limpa o timeout antes de chamar a função para rolar para baixo
                scrollToBottom(); // Chama a função para rolar para baixo
            }
        }
        step();
    }

    scrollToBottom(); // Inicia rolando para baixo
}

export { statusFullScreen }