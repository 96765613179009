
import { computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref, watch } from "vue";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import { TODAY } from "@/core/data/events";
import VisualizarServicoModal from "@/views/agenda-virtual/components/modais/servicos/VisualizarServicoModal.vue";
import { ContentLoader } from 'vue-content-loader';
import { Modal } from "bootstrap";
import moment, { Moment } from "moment";
import { getServicosAgendados } from '@/services/AgendaService';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { config } from "@/views/agenda-virtual/ConfigEvents";
import useAlert from "@/composables/Alert";
import useEmitter from '@/composables/Emmiter';
import AuthService from "@/services/AuthService";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import ptBr from 'element-plus/lib/locale/lang/pt-br';
import { Actions } from "@/store/enums/StoreEnums";
import useUtilChanges from "@/composables/UtilChanges";
import { AgendaServico } from "@/models/AgendaModel";
import {changeStoppedDayUpdate} from '@/views/agenda-virtual/utils/changeStopDayUtils'
import { statusFullScreen } from '@/views/agenda-virtual/utils/scrollUtils'

interface TrataServico extends AgendaServico {
  endWithout: string
}

export default defineComponent({
  name: "agenda-virtual-exibir-hyundai",
  components: {
    FullCalendar,
    VisualizarServicoModal,
    ContentLoader
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const emitter = useEmitter();
    const userInfo = AuthService.getUsuarioAuth();
    const codNivel = userInfo.codNivel;
    const isUserActive = ref<any>(false);
    const refCalendarHyundaiServicos:any = ref(null);
    const eventInfo:any = ref();
    const consultores:any = ref([]);
    const concessionariaInfo:any = ref({});
    const dataMesAtual = moment().startOf("day").toISOString();
    const dateCalendarFilter = ref(dataMesAtual);
    const loading:any = ref(true);
    const resources:any = ref([]);
    let events:any = ref([]);
    const isUserFastActive = ref<any>(false);
    const timeNow = ref(moment().format("HH:mm:ss"));
    const { replacePlaca, cutChassi } = useUtilChanges();
    const countClickPrevNext = ref(0)    

    async function horariosQuadro() {
      const concessionaria = store.getters.concessionariaSelectedInfo

      if(!(Object.keys(concessionaria).length === 0)){
        calendarOptions.value.slotMinTime = moment(concessionaria.horaAbertura, "H").format("HH:mm:ss")
        calendarOptions.value.slotMaxTime = moment(concessionaria.horaFechamento, "H").format("HH:mm:ss")
        calendarOptions.value.businessHours.startTime = moment(concessionaria.horaAbertura, "H").format("HH:mm")
        calendarOptions.value.businessHours.endTime = moment(concessionaria.horaFechamento, "H").format("HH:mm")
      }
    }

    const calendarOptions = ref({
      schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
      plugins: [resourceTimelinePlugin, dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, resourceTimeGridPlugin ],
      headerToolbar: {
        left: "",
        center: "prev title next",
        right: ""
        // right: "dayGridMonth,timeGridWeek,timeGridDay",
      },
      customButtons: {
        prev: {
          click: () => {
            refCalendarHyundaiServicos.value.getApi().prev();
            countClickPrevNext.value--
            controlaFiltro();
          }
        },
        next: {
          click: () => {
            refCalendarHyundaiServicos.value.getApi().next();
            countClickPrevNext.value++
            controlaFiltro();
          }
        },
      },
      nowIndicator: true,
      businessHours: {
        // days of week. an array of zero-based day of week integers (0=Sunday)
        daysOfWeek: [1, 2, 3, 4, 5, 6], // Monday - Thursday
        
        startTime: '07:00', // a start time (10am in this example)
        endTime: '19:00', // an end time (6pm in this example)
      },
      resourceOrder: 'nome',
      slotMinTime: '07:00:00',
      slotMaxTime: '19:00:00',
      allDaySlot: false,
      locale: "pt-br",
      scrollTime: moment().subtract(2.25, "hours").format("HH:mm"),
      initialDate: TODAY,
      snapDuration: "00:15",

      initialView: 'resourceTimelineDay',
      eventColor: 'blue',
      eventBorderColor: 'blue',
      slotMinWidth: "130",
      contentHeight: "auto",
      eventBackgroundColor: 'blue',
      events: events.value,
      resources: resources.value,
      eventDurationEditable: false,
      resourceAreaWidth: '10%',
      resourceAreaColumns: [
        // {
        //   field: 'num',
        //   headerContent: 'Nº',
        //   width: 10,
        //   group: true,
        // },
        {
          field: 'title',
          headerContent: 'Técnico',
          width: 60,
          cellContent: function (info) {
            let propriedades;
            if(info.fieldValue){
              propriedades = JSON.parse(info.fieldValue)
            } else if(info.groupValue){
              propriedades = JSON.parse(info.groupValue)
            }

            const html = `<div class="calendar-image-hyundai-serv"><img src="${propriedades.imagem}"><div class="calendar-field-hyundai-serv">${trataNome(propriedades.nome)}</div></div>`
            return { html };
          },
          group: true,
        },
        // {
        //   field: 'servico',
        //   headerContent: 'Serviço',
        //   width: 25,
        //   group: true,
        // },
        {
          field: 'status',
          headerContent: '',
          width: 40,
        },
      ],
      views: {
        resourceTimelineDay: { 
          buttonText: "dia",
        },
        // timeGridWeek: { buttonText: "semana" },
        // dayGridMonth: { buttonText: "mês" },
      },
      editable: false,
      dayMaxEvents: true, // allow "more" link when too many events
      slotLabelFormat: {
          hour: '2-digit',
          minute: '2-digit',
          omitZeroMinute: false,
          meridiem: 'short'
      },
      eventClick: (info) => openVisualizarServicoModal(info),
      eventContent: function (info, createElement) {
        const start = moment(info.event.start);
        const end = moment(info.event.end);

        const eventInfo = info.event;

        const propriedades = eventInfo.extendedProps;
        let htmlContent =  "";
        let htmlFooter =  "";
        let htmlWait =  "";
        let htmlDuration =  "";
        let htmlTimeAdditional =  "";
        let tempoAdicional = 0;
        const tiposServico = [9,10,11,12] //eventos extra
        
        const duration = moment.duration(end.diff(start)).asMinutes();

        if(propriedades.tempoAdicional){
          tempoAdicional = 4.333 * propriedades.tempoAdicional
          
          htmlTimeAdditional = `<span class='title-time-additional'>+${propriedades.tempoAdicional}  ${propriedades.tempoAdicional > 15 ? 'min': ''}</span>`
        }

        if(propriedades.hasContent){
          htmlContent = `
          <div class="event-content ">
            <div class="event-content-info">
              <div>${propriedades.showDuration && duration > 15 ? 'Recepção' : 'R'}:</div>
              <div  class="info-date ${duration <= 15 ? 'd-none' : 'd-none'}">${moment(propriedades.recepcao).format("DD/MM")}</div>
              <div  class"info-time">${moment(propriedades.recepcao).format(" HH:mm")}</div>
            </div>
              <div class="event-content-info"><div class="info-label">${propriedades.showDuration && duration > 15 ? 'Entrega' : 'E'}:</div>
                <div  class="info-date ${duration <= 15 ? 'd-none' : 'd-none'}">${moment(propriedades.entrega).format("DD/MM")}</div>
                <div  class="info-time">${moment(propriedades.entrega).format("HH:mm")}</div>
              </div>
            </div>`;
          htmlWait =  `<div class="event-wait">${propriedades.aguarda ? '<div class="wait-indicator"></div>' : ''}</div>`
        }

        if(propriedades.hasFooter){
          htmlFooter = `<div style="${"color:" + propriedades.footerTextColor + ';'} ${'color:' + propriedades.footerTextColor + ';' +
            (tempoAdicional ? 'background: linear-gradient(to right, transparent 0%, transparent calc(100% - ' + tempoAdicional + 'px), var(--cor_layout_1) calc(100% - ' + tempoAdicional + 'px), var(--cor_layout_1) 100%);' : '')} ${ 'background-color:' + propriedades.bgColor}" class="event-footer ${duration <= 15 ? 'flex-column' : ''}"><div class="text-overflow ${duration <= 15 ? 'd-none' : ''}"><svg class="d-none" width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.9375 4.5C5.18027 4.5 6.1875 3.49277 6.1875 2.25C6.1875 1.00723 5.18027 0 3.9375 0C2.69473 0 1.6875 1.00723 1.6875 2.25C1.6875 3.49277 2.69473 4.5 3.9375 4.5ZM5.5125 5.0625H5.21895C4.82871 5.2418 4.39453 5.34375 3.9375 5.34375C3.48047 5.34375 3.04805 5.2418 2.65606 5.0625H2.3625C1.0582 5.0625 0 6.1207 0 7.425V8.15625C0 8.62207 0.37793 9 0.84375 9H7.03125C7.49707 9 7.875 8.62207 7.875 8.15625V7.425C7.875 6.1207 6.8168 5.0625 5.5125 5.0625Z" fill="#464E5F"/></svg>${propriedades.consultorNome}</div><div class="text-overflow"><svg class="d-none" width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="12.0909" height="8" rx="1.5" stroke="#464E5F"/><path d="M0 2C0 0.89543 0.895431 0 2 0H11.0909C12.1955 0 13.0909 0.895431 13.0909 2V4.5H0V2Z" fill="#464E5F"/></svg>${propriedades.placa ? replacePlaca(propriedades.placa) : cutChassi(propriedades.chassi,-6,'...')}</div></div>`;
        }

        if(propriedades.showDuration && duration > 15){
          htmlDuration =  `- ${tempoAdicional ? duration - propriedades.tempoAdicional : duration} min`;
        }
        
        const html = `
          <div class="event" style="${propriedades.osAberta || tiposServico.includes(propriedades.codTipoServico) ? '' :'opacity: 50%;'} ${!propriedades.hasContent ? 'background-color:' + propriedades.bgColor : 'background-color: white'}">
            <div class="event-header" style="${(tempoAdicional ? 'background: linear-gradient(to right, transparent 0%, transparent calc(100% - ' + tempoAdicional + 'px), var(--cor_layout_1) calc(100% - ' + tempoAdicional + 'px), var(--cor_layout_1) 100%);' : '')};color: ${propriedades.headerTextColor}; background-color: ${propriedades.bgColor}">
              <span class="event-title ${duration <= 15 ? 'event-title-min' : ''} ${propriedades.tempoAdicional ? 'event-title-additional-time' : ''} ${propriedades.allInfo.codTipoServico > 8 ? 'text-truncate' : '' }" style="color: ${propriedades.headerTextColor}">${propriedades.status == 5 ? '<i style="color:#ffffff" class="bi bi-stopwatch-fill"></i>' : '' } ${eventInfo.title} ${htmlDuration} ${htmlTimeAdditional}</span>${htmlWait}</div>${htmlContent}${htmlFooter}</div>`;
        return { html };
      }
    });
    const tecnicos:any = ref([]);

    const refIntervals = reactive({
      baseAgendamento: 0,
      baseServicos: 0,
      timeNow: 0
    });

    const lastUpdatedServicos:any = ref(null);

    // LIFECYCLE HOOKS
    onMounted(() => {

      statusFullScreen('#card-calendar-hyundai-servicos')

      horariosQuadro()
      concessionariaInfo.value = store.getters.concessionariaSelectedInfo;           
     
      if(store.getters.consultoresList.length > 0){
        consultores.value = store.getters.consultoresList;
      }

      if(store.getters.mecanicosList.length > 0){
        tecnicos.value = store.getters.mecanicosList;
        trataMecanicos();
      }

      startPage();

     // EMITTER HOOKS
      emitter.on("update-agenda-servicos", () => {
        startPage();
      });

      emitter.on("agenda-clear-interval", () => {
        clearAllSetInterval();
      });

      emitter.on("update-calendar", () => {
        controlaFiltro();
      });

      refIntervals.timeNow = setInterval(() => {
        timeNow.value = moment().format("HH:mm:ss");        
      }, 1000);
    });

    store.watch(()=>store.getters.concessionariasList , ()=> {
        if(store.getters.concessionariasList) horariosQuadro()
      })

    onBeforeUnmount(() => {
      if(refIntervals.timeNow){
        clearInterval(refIntervals.timeNow);
      };
      clearAllSetInterval();

      emitter.off("update-agenda-servicos");
      emitter.off("agenda-clear-interval");
      emitter.off("update-calendar");
    });

    // WATCH HOOKS
    store.watch(() => store.getters.concessionariaSelectedInfo, () => {
      concessionariaInfo.value = store.getters.concessionariaSelectedInfo;
    }, { deep: true });

    store.watch(() => store.getters.baseServicosList, () => {
      const dateFilter = moment(refCalendarHyundaiServicos.value?.getApi()?.getDate() ?? undefined);

      if(dateFilter.isSame(moment(), "day") && store.getters.baseServicosList.length > 0){
        trataServicos(store.getters.baseServicosList);
      }
    }, { deep: true });

    store.watch(() => store.getters.consultoresList, () => {
      consultores.value = store.getters.consultoresList;
    }, { deep: true });

    store.watch(() => store.getters.mecanicosList, () => {
      tecnicos.value = store.getters.mecanicosList;

      trataMecanicos();
    }, { deep: true });

    // FUNCTIONS
    function startPage(){
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 500);
      inactivityTime();
      fastInactivityTime();

      const auxDateSelected = moment(refCalendarHyundaiServicos.value?.getApi()?.getDate() ?? undefined);
      const auxUpdateTimeServico = store.getters.refLastUpdatedTime.lastBaseServicos;
      const auxUpdateTimeAgendamento = store.getters.refLastUpdatedTime.lastBaseAgendamentos;

      clearAllSetInterval();

      if(auxDateSelected.isSame(moment(), "day")){
        if(!auxUpdateTimeServico || moment().isAfter(auxUpdateTimeServico.clone().add(60, "seconds"))){
          store.dispatch(Actions.SET_BASE_SERVICOS).then(() => {
            loading.value = false;
          });
        } else {
          trataServicos(store.getters.baseServicosList);
        }

        refIntervals.baseServicos = setInterval(() => {
          store.dispatch(Actions.SET_BASE_SERVICOS);
        }, 90000);

        //  TODO Adicionar a lógica quando o período for diferente ao implementar base de OS abertas
        // if(!auxUpdateTimeAgendamento || moment().isAfter(auxUpdateTimeAgendamento.clone().add(210, "seconds"))){
        //   auxUpdateTimeAgendamento.value = moment();
        //   store.dispatch(Actions.SET_BASE_AGENDAMENTOS);
        // }

        // // refIntervals.baseAgendamento = setInterval(() => {
        //   store.dispatch(Actions.SET_BASE_AGENDAMENTOS);
        // }, 300000);
      } else {
        if(!lastUpdatedServicos.value || moment().isAfter(lastUpdatedServicos.value.clone().add(60, "seconds"))) {
          controlaFiltro();
        }
      }
    };

    function clearAllSetInterval(){
      clearInterval(refIntervals.baseServicos);
    };

    function changeCalendarDate(date){
      if(date){
        refCalendarHyundaiServicos.value.getApi().gotoDate(date);

        controlaFiltro();
      }
    };

    async function trataMecanicos(){
      while(resources.value.length) {
        resources.value.pop();
      }

      tecnicos.value.forEach((tecnico, index) => {
        const resource = {
          id: `${tecnico.codMecanico}_aplano`,
          title: JSON.stringify({
            nome: tecnico.nome,
            imagem: tecnico.imagem,
          }),
          nome: tecnico.nome,
          servico: "",
          num: index + 1,
          eventColor: "none",
          status: "Plano"
        };
        const resourceB = {
          id: `${tecnico.codMecanico}_execucao`,
          title: JSON.stringify({
            nome: tecnico.nome,
            imagem: tecnico.imagem,
          }),
          servico: "",
          num: index + 1,
          eventColor: "none",
          status: "Início/Fim"
        };

        resources.value.push(resource);
        resources.value.push(resourceB);
      });
    };

    function clearCalendar(){
      const eventsCalendar = refCalendarHyundaiServicos.value?.getApi()?.getEvents() ?? [];
      eventsCalendar.forEach(event => {
        event.remove();
      });
    };

    function removeAcentos(name, toLowerCase = false) {
      if(!name){
        return "";
      }
      const stringTratado = name.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      return toLowerCase ? stringTratado.toLowerCase() : stringTratado;
    };

    async function buscaServicos(showLoading){
      const dateFilter = moment(refCalendarHyundaiServicos.value?.getApi()?.getDate() ?? undefined);
      lastUpdatedServicos.value = dateFilter.clone();

      try{
        if(showLoading){
          loading.value = true;
        }

        if(dateFilter.isSame(moment(), "day")){
          clearAllSetInterval();
          await store.dispatch(Actions.SET_BASE_SERVICOS);

          refIntervals.baseServicos = setInterval(() => {
            store.dispatch(Actions.SET_BASE_SERVICOS);
          }, 90000);
        } else {
          const response = await getServicosAgendados(concessionariaInfo.value.codConcessionaria, dateFilter.format("YYYY-MM-DD"));

          if(response.length > 0){
            trataServicos(response);
          }
        }
      } finally {
        loading.value = false;
      }
    }

    async function controlaFiltro() {
      const dateFilter = moment(refCalendarHyundaiServicos.value?.getApi()?.getDate() ?? undefined);

      clearAllSetInterval();

      if(dateFilter.isSame(moment(), "day")){
        loading.value = true;
        await store.dispatch(Actions.SET_BASE_SERVICOS);
        loading.value = false;

        refIntervals.baseServicos = setInterval(() => {
          store.dispatch(Actions.SET_BASE_SERVICOS);
        }, 90000);
      } else {
        buscaServicos(true);

        refIntervals.baseServicos = setInterval(() => {
          buscaServicos(false);
          changeStoppedDayUpdate(countClickPrevNext.value,refCalendarHyundaiServicos.value,controlaFiltro)
        }, 90000);
      }
    };

    async function trataServicos(servicos : TrataServico[]){
      try {
        clearCalendar();
       
        servicos.forEach((servico) => {
          const eventConfig = config.events[servico.codTipoServico - 1];
          let newEvent;

          if(servico.tempoAdicional){
            const end = moment(servico.dataFinalServico.replace(" ", "T"), "YYYY-MM-DD HH:mm:ss");
            servico.endWithout = end.subtract(servico.tempoAdicional, "minutes").format("YYYY-MM-DD HH:mm:ss");
          } else {
            servico.endWithout = servico.dataInicioServico.replace(" ", "T");
          }

          let statusText = "";

          if(servico.status == 1 || servico.status == 5){
            statusText = "_aplano";
          } else if(servico.status == 2) {
            statusText = "_execucao";
          }

          if(servico.codTipoServico < 9){
            let auxNomeConsultor = consultores.value.find(consultor => removeAcentos(consultor.intConsultor, true) == removeAcentos(servico.consultorAgendado, true))?.nomeTratado;

            if(!auxNomeConsultor){
              auxNomeConsultor = consultores.value.find(consultor => removeAcentos(consultor.nome, true) == removeAcentos(servico.consultorAgendado, true))?.nomeTratado;
            }

            newEvent = {
              id: servico.codServico,
              resourceId: `${servico.codTecnico.toString()}${statusText}`,
              title: eventConfig.title,
              start: servico.dataInicioServicoExecucao ? servico.dataInicioServicoExecucao.replace(" ", "T") : servico.dataInicioServico.replace(" ", "T"),
              end: servico.dataFinalServicoExecucao ? servico.dataFinalServicoExecucao : servico.dataFinalServico.replace(" ", "T"),
              extendedProps: {
                showDuration: eventConfig.extendedProps.showDuration,
                aguarda: servico.aguarda == 1 ? true : false,
                hasContent: eventConfig.extendedProps.hasContent,
                hasFooter: eventConfig.extendedProps.hasFooter,
                cliente: trataNome(servico.cliente),
                consultor: servico.consultorAgendado,
                consultorNome: auxNomeConsultor ?? "",
                recepcao: servico.dataRecepcao.replace(" ", "T"), 
                entrega: servico.dataEntrega.replace(" ", "T"),
                placa: servico.placa,
                chassi: servico.chassi,
                tempoAdicional: servico.tempoAdicional,
                osAberta: servico.osAberta,
                codTipoServico: servico.codTipoServico,
                status: servico.status,
                bgColor: eventConfig.extendedProps.bgColor,
                footerTextColor: eventConfig.extendedProps.footerTextColor,
                headerTextColor: eventConfig.extendedProps.headerTextColor,
                allInfo: servico,
              }
            };
          } else if(servico.codTipoServico == 9){
            newEvent = {
              id: servico.codServico,
              resourceId: `${servico.codTecnico.toString()}${statusText}`,
              title: eventConfig.title,
              daysOfWeek: [1, 2, 3, 4, 5, 6],
              startTime: servico.dataInicioServicoExecucao ?  moment(servico.dataInicioServicoExecucao, "YYYY-MM-DD HH:mm:ss").format("HH:mm") : moment(servico.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm"),
              endTime: moment( servico.dataFinalServicoExecucao ? servico.dataFinalServicoExecucao : servico.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm"),
              extendedProps: {
                showDuration: eventConfig.extendedProps.showDuration,
                hasContent: eventConfig.extendedProps.hasContent,
                hasFooter: eventConfig.extendedProps.hasFooter,
                bgColor: eventConfig.extendedProps.bgColor,
                osAberta: servico.osAberta,
                status: servico.status,
                codTipoServico: servico.codTipoServico,
                tempoAdicional: servico.tempoAdicional,
                footerTextColor: eventConfig.extendedProps.footerTextColor,
                headerTextColor: eventConfig.extendedProps.headerTextColor,
                allInfo: servico,
              }
            };
          } else if(servico.codTipoServico == 10 || servico.codTipoServico == 11){
            newEvent = {
              id: servico.codServico,
              resourceId: `${servico.codTecnico.toString()}${statusText}`,
              title: eventConfig.title,
              start: servico.dataInicioServicoExecucao ?  moment(servico.dataInicioServicoExecucao, "YYYY-MM-DD HH:mm:ss").format("HH:mm") : moment(servico.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
              end: moment(servico.dataFinalServicoExecucao ? servico.dataFinalServicoExecucao : servico.dataFinalServico, "YYYY-MM-DD HH:mm:ss").endOf("day").format("YYYY-MM-DD HH:mm:ss"),
              extendedProps: {
                showDuration: eventConfig.extendedProps.showDuration,
                hasContent: eventConfig.extendedProps.hasContent,
                hasFooter: eventConfig.extendedProps.hasFooter,
                bgColor: eventConfig.extendedProps.bgColor,
                osAberta: servico.osAberta,
                status: servico.status,
                codTipoServico: servico.codTipoServico,
                tempoAdicional: servico.tempoAdicional,
                headerTextColor: eventConfig.extendedProps.headerTextColor,
                footerTextColor: eventConfig.extendedProps.footerTextColor,
                allInfo: servico,
              }
            };
          } else if(servico.codTipoServico == 12){
            newEvent = {
              id: servico.codServico,
              resourceId: `${servico.codTecnico.toString()}${statusText}`,
              title: servico.observacao,
              start: servico.dataInicioServicoExecucao ? servico.dataInicioServicoExecucao.replace(" ", "T") : servico.dataInicioServico.replace(" ", "T"),
              end: servico.dataFinalServicoExecucao ? servico.dataFinalServicoExecucao : servico.dataFinalServico.replace(" ", "T"),
              extendedProps: {
                showDuration: eventConfig.extendedProps.showDuration,
                hasContent: eventConfig.extendedProps.hasContent,
                hasFooter: eventConfig.extendedProps.hasFooter,
                bgColor: eventConfig.extendedProps.bgColor,
                osAberta: servico.osAberta, 
                status: servico.status,
                codTipoServico: servico.codTipoServico,
                tempoAdicional: servico.tempoAdicional,
                headerTextColor: eventConfig.extendedProps.headerTextColor,
                footerTextColor: eventConfig.extendedProps.footerTextColor,
                allInfo: servico,
              }
            };
          }

          refCalendarHyundaiServicos.value.getApi().addEvent(newEvent);
        });

        const calendarioDataAtual = moment().isSame(moment(refCalendarHyundaiServicos.value?.getApi()?.getDate() ?? undefined), 'day');
        if(calendarioDataAtual && !isUserActive.value){
          refCalendarHyundaiServicos.value?.getApi()?.scrollToTime(moment().subtract(2.25, "hours").format("HH:mm"));
        }
      } catch (e) {
        console.error(e);
        // showTimeAlert('Não foi possível completar a solicitação', "error");
      } finally {
        loading.value = false;
      }
    }

    function calendarFullscreen() {
      if(document.fullscreenElement){
        document.exitFullscreen();
        return
      } 
      let elem:any = document.querySelector("#card-calendar-hyundai-servicos");
      if (elem.requestFullscreen) {
          elem.requestFullscreen({ navigationUI: "show" });
      } else if (elem.mozRequestFullScreen) { /* Firefox */
          elem.mozRequestFullScreen({ navigationUI: "show" });
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
          elem.webkitRequestFullscreen({ navigationUI: "show" });
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
          elem.msRequestFullscreen({ navigationUI: "show" });
      }
    };

    function openVisualizarServicoModal(info) {
      if(document.fullscreenElement){
        document.exitFullscreen();
      } 
      eventInfo.value = info.event?.extendedProps?.allInfo;
      const modal = new Modal(document.getElementById("kt_modal_event_info_3"));
      modal.show();
    };

    function inactivityTime () {
      let time;
      // reset timer
      window.onload = resetTimer;
      document.onmousemove = resetTimer;
      document.onkeydown = resetTimer;
      function doSomething() {
        isUserActive.value = false;
      }
      function resetTimer() {
        isUserActive.value = true;
        clearTimeout(time);
        time = setTimeout(doSomething, 60000)
      }
    };

    function fastInactivityTime() {
      let time;
      // reset timer
      window.onload = resetTimer;
      document.onmousemove = resetTimer;
      document.onkeydown = resetTimer;
      function doSomething() {
        isUserFastActive.value = false;
      }
      function resetTimer() {
        isUserFastActive.value = true;
        clearTimeout(time);
        time = setTimeout(doSomething, 5000)
      }
    };

    function trataNome(nome){
      if(!nome || nome == "undefined" || nome == undefined){
        return "";
      }
      
      const arrayNomes = nome.split(" ");
      const nomeTratado = `${arrayNomes[0]}` 

      return nomeTratado;
    }

    return {
      tecnicos,
      calendarOptions,
      events,
      refCalendarHyundaiServicos,
      resources,
      eventInfo,
      calendarFullscreen,
      loading,
      concessionariaInfo,
      codNivel,
      isUserFastActive,
      headerTitle: computed(() => store.getters.concessionariaSelectedInfo.nomeFantasia),
      timeNow,
      ptBr,
      changeCalendarDate,
      dateCalendarFilter,
      controlaFiltro,
      replacePlaca
    };
  },
});
